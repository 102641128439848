import CodeTest from '@/components/task-templates/CodeTest'
import Intro from './Intro'
import Comparator from './Comparator'

export default [
  {
    component: Intro,
    label: '!'
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Квадрат за O(1)',
    text: 'Напишите функцию \\(sqr1\\), которая принимает число \\(n\\) и возвращает его квадрат',
    note: null,
    examples: [
      {
        input: `
          console.log(sqr1(4))
          console.log(sqr1(1000))
        `,
        output: `
          16
          1000000
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Квадрат за O(n)',
    text: 'Напишите функцию \\(sqr2\\), которая принимает число \\(n\\) и возвращает его квадрат, но делает это суммированием значения \\(n\\) за \\(n\\) шагов',
    note: null,
    examples: [
      {
        input: `
          console.log(sqr2(4))
          console.log(sqr2(1000))
        `,
        output: `
          16
          1000000
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Квадрат за O(n * n)',
    text: 'Напишите функцию \\(sqr3\\), которая принимает число \\(n\\) и возвращает его квадрат, но делает это сложением единиц за \\(n * n\\) шагов (в двойном цикле)',
    note: null,
    examples: [
      {
        input: `
          console.log(sqr3(4))
          console.log(sqr3(1000))
        `,
        output: `
          16
          1000000
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время sqr',
    text: 'Без функции. Запустить код для тестирования. При каждом запуске результаты могут немного отличаться. Понятно ли, что происходит? Что за числа получаются? Какие выводы можно сделать?',
    note: null,
    examples: [
      {
        input: `
          console.log(getTimeOfManyTimes(30000, sqr1, 10000))
          console.log(getTimeOfManyTimes(30000, sqr2, 10000))
          console.log(getTimeOfManyTimes(30000, sqr3, 10000))
        `,
        output: `
          ?
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сумма арифметической прогрессии за O(n)',
    text: 'Предположим, что есть последовательность чисел, начинающаяся от числа \\(a\\), каждое следующее число на \\(d\\) больше предыдущего. Напишите функцию \\(sumArithmProg1\\), принимающую \\(a\\), \\(d\\) и \\(n\\), возвращающую сумму первых \\(n\\) чисел из последовательности',
    note: 'Нужно реализовать решение циклом',
    examples: [
      {
        input: `
          console.log(sumArithmProg1(0, 5, 3))
          console.log(sumArithmProg1(10, 10, 4))
          console.log(sumArithmProg1(-100, 50, 5))
        `,
        output: `
          15
          100
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сумма арифметической прогрессии за O(1)',
    text: 'Предположим, что есть последовательность чисел, начинающаяся от числа \\(a\\), каждое следующее число на \\(d\\) больше предыдущего. Напишите функцию \\(sumArithmProg2\\), принимающую \\(a\\), \\(d\\) и \\(n\\), возвращающую сумму первых \\(n\\) чисел из последовательности',
    note: 'Нужно реализовать решение без цикла, используя формулу суммы арифметической прогрессии \\(S = \\frac{a_1+a_n}{2} \\cdot n\\), где \\(a_1\\) - первый член прогрессии, \\(a_n\\) - последний, \\(n\\) - количество чисел',
    examples: [
      {
        input: `
          console.log(sumArithmProg2(0, 5, 3))
          console.log(sumArithmProg2(10, 10, 4))
          console.log(sumArithmProg2(-100, 50, 5))
        `,
        output: `
          15
          100
          0
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время sumArithmProg',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          console.log(getTimeOfManyTimes(10000, sumArithmProg1, 1, 1, 10000))
          console.log(getTimeOfManyTimes(10000, sumArithmProg2, 1, 1, 10000))
        `,
        output: `
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Получаем делители числа за O(n)',
    text: 'Напишите функцию \\(getAllDivisors1\\), принимающую число \\(n\\). Функция должна вернуть массив со всеми делителями числа (порядок чисел не важен)',
    note: 'Нужно реализовать полным перебором. Перебираем все числа от 1 до \\(n\\)',
    examples: [
      {
        input: `
          console.log(getAllDivisors1(20))
          console.log(getAllDivisors1(49))
          console.log(getAllDivisors1(50))
        `,
        output: `
          [1, 2, 4, 5, 10, 20]
          [1, 7, 49]
          [1, 2, 5, 10, 25, 50]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Получаем делители числа за O(sqrt(n))',
    text: 'Напишите функцию \\(getAllDivisors2\\), принимающую число \\(n\\). Функция должна вернуть массив со всеми делителями числа (порядок чисел не важен)',
    note: 'Нужно реализовать перебором делителей до \\(\\sqrt{n}\\), получая по два делителя на каждом шаге',
    examples: [
      {
        input: `
          console.log(getAllDivisors2(20))
          console.log(getAllDivisors2(49))
          console.log(getAllDivisors2(50))
        `,
        output: `
          [1, 20, 2, 10, 4, 5]
          [1, 49, 7]
          [1, 50, 2, 25, 5, 10]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время getAllDivisors',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          console.log(getTimeOfManyTimes(10000, getAllDivisors1, 123456))
          console.log(getTimeOfManyTimes(10000, getAllDivisors2, 123456))
        `,
        output: `
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Заполняем массив с известной длиной',
    text: 'Напишите функцию \\(fillArray1\\), принимающую число \\(n\\). Функция должна вернуть массив, заполненный числами от 0 до \\(n - 1\\) включительно',
    note: 'При создании массива нужно указать его размер',
    examples: [
      {
        input: `
          console.log(fillArray1(4))
          console.log(fillArray1(7))
        `,
        output: `
          [0, 1, 2, 3]
          [0, 1, 2, 3, 4, 5, 6]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Заполняем массив с неизвестной длиной без push',
    text: 'Напишите функцию \\(fillArray2\\), принимающую число \\(n\\). Функция должна вернуть массив, заполненный числами от 0 до \\(n - 1\\) включительно',
    note: 'Нужно создать пустой массив, а значения также записываем по индексам',
    examples: [
      {
        input: `
          console.log(fillArray2(4))
          console.log(fillArray2(7))
        `,
        output: `
          [0, 1, 2, 3]
          [0, 1, 2, 3, 4, 5, 6]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Заполняем массив с неизвестной длиной с push',
    text: 'Напишите функцию \\(fillArray3\\), принимающую число \\(n\\). Функция должна вернуть массив, заполненный числами от 0 до \\(n - 1\\) включительно',
    note: 'Нужно создать пустой массив, а значения добавлять методом push',
    examples: [
      {
        input: `
          console.log(fillArray1(4))
          console.log(fillArray1(7))
        `,
        output: `
          [0, 1, 2, 3]
          [0, 1, 2, 3, 4, 5, 6]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время fillArray',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          console.log(getTimeOfManyTimes(10, fillArray1, 10000000))
          console.log(getTimeOfManyTimes(10, fillArray2, 10000000))
          console.log(getTimeOfManyTimes(10, fillArray3, 10000000))
        `,
        output: `
          ?
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Считаем уникальные элементы с дополнительным массивом',
    text: 'Напишите функцию \\(countUnique1\\), принимающую числовой массив \\(a\\) и возвращающую количество уникальных элементов в нём',
    note: 'Нужно реализовать, используя дополнительный массив с уникальными элементами',
    examples: [
      {
        input: `
          console.log(countUnique1([1, 2, 3, 3, 3, 4]))
          console.log(countUnique1([2, 8, 2, 9, 8, 9]))
          console.log(countUnique1([1, 1, 1, 2, 2, 2]))
        `,
        output: `
          4
          3
          2
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Считаем уникальные элементы перебором',
    text: 'Напишите функцию \\(countUnique2\\), принимающую числовой массив \\(a\\) и возвращающую количество уникальных элементов в нём',
    note: 'Нужно реализовать без дополнительного массива, сравнивая каждое число со всеми предыдущими',
    examples: [
      {
        input: `
          console.log(countUnique2([1, 2, 3, 3, 3, 4]))
          console.log(countUnique2([2, 8, 2, 9, 8, 9]))
          console.log(countUnique2([1, 1, 1, 2, 2, 2]))
        `,
        output: `
          4
          3
          2
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Считаем уникальные элементы объектом',
    text: 'Напишите функцию \\(countUnique3\\), принимающую числовой массив \\(a\\) и возвращающую количество уникальных элементов в нём',
    note: 'Нужно реализовать, используя объект, ключами которого будут уникальные элементы массива',
    examples: [
      {
        input: `
          console.log(countUnique3([1, 2, 3, 3, 3, 4]))
          console.log(countUnique3([2, 8, 2, 9, 8, 9]))
          console.log(countUnique3([1, 1, 1, 2, 2, 2]))
        `,
        output: `
          4
          3
          2
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время countUnique',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          const a = Array(100000)
          for (let i = 0; i < a.length; i++) {
              a[i] = Math.ceil(Math.random() * 10000)
          }
          console.log(getTimeOfManyTimes(10, countUnique1, a))
          console.log(getTimeOfManyTimes(10, countUnique2, a))
          console.log(getTimeOfManyTimes(10, countUnique3, a))
        `,
        output: `
          ?
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Пузырьковая сортировка',
    text: 'Напишите функцию \\(bubbleSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом пузырька и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(bubbleSort([5, 4, 3, 2, 1]))
          console.log(bubbleSort([1, 4, 2, 3, 6, 5]))
          console.log(bubbleSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка вставками',
    text: 'Напишите функцию \\(insertionSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом вставок и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(insertionSort([5, 4, 3, 2, 1]))
          console.log(insertionSort([1, 4, 2, 3, 6, 5]))
          console.log(insertionSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка выбором',
    text: 'Напишите функцию \\(selectionSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом выбора и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(selectionSort([5, 4, 3, 2, 1]))
          console.log(selectionSort([1, 4, 2, 3, 6, 5]))
          console.log(selectionSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Быстрая сортировка',
    text: 'Напишите функцию \\(quickSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом быстрой сортировки и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(quickSort([5, 4, 3, 2, 1]))
          console.log(quickSort([1, 4, 2, 3, 6, 5]))
          console.log(quickSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка слиянием',
    text: 'Напишите функцию \\(mergeSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом слияния и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(mergeSort([5, 4, 3, 2, 1]))
          console.log(mergeSort([1, 4, 2, 3, 6, 5]))
          console.log(mergeSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка кучей',
    text: 'Напишите функцию \\(heapSort\\), принимающую числовой массив \\(a\\). Функция должна отсортировать этот массив по неубыванию методом кучи и вернуть ссылку на него же. Для построения кучи понадобится рекурсивная функция \\(heapify\\)',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(heapSort([5, 4, 3, 2, 1]))
          console.log(heapSort([1, 4, 2, 3, 6, 5]))
          console.log(heapSort([1, 4, 2, 3, 1, 4]))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [1, 1, 2, 3, 4, 4]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка подсчётом',
    text: 'Напишите функцию \\(countSort\\), принимающую числовой массив \\(a\\) и числа \\(min\\) и \\(max\\). Элементы массива \\(a\\) гарантированно не будут меньше \\(min\\) и больше \\(max\\). Функция должна отсортировать этот массив по неубыванию методом подсчёта и вернуть ссылку на него же',
    note: 'Какая асимптотика у этого алгоритма по времени и по памяти?',
    examples: [
      {
        input: `
          console.log(countSort([5, 4, 3, 2, 1], 1, 5))
          console.log(countSort([1, 4, 2, 3, 6, 5], 0, 10))
          console.log(countSort([25, -30, 10, 15, 10, 30], -30, 30))
        `,
        output: `
          [1, 2, 3, 4, 5]
          [1, 2, 3, 4, 5, 6]
          [-30, 10, 10, 15, 25, 30]
        `
      }
    ]
  },
  {
    label: '!',
    component: Comparator
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Числа по возрастанию',
    text: 'Напишите функцию \\(numberAsc\\), принимающую два числа, и возвращающую отрицательное число, если первое меньше, положительное, если больше, и 0, если числа равны',
    note: null,
    examples: [
      {
        input: `
          console.log(numberAsc(1, 2) < 0)
          console.log(numberAsc(2, 1) > 0)
          console.log(numberAsc(2, 2) === 0)
          console.log(numberAsc(1, 2) >= 0)
          console.log(numberAsc(-1, -2) <= 0)
          console.log(numberAsc(-1, -1) !== 0)
        `,
        output: `
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Строки по убыванию',
    text: 'Напишите функцию \\(stringDesc\\), сравнивающую две строки без учёта регистра. Должна возвращать отрицательное число, если первая строка больше, положительное, если меньше, и 0, если строки равны',
    note: null,
    examples: [
      {
        input: `
          console.log(stringDesc('aa', 'aaa') > 0)
          console.log(stringDesc('ab', 'aa') < 0)
          console.log(stringDesc('aa', 'AA') === 0)
          console.log(stringDesc('bc', 'cb') <= 0)
          console.log(stringDesc('b', 'A') >= 0)
          console.log(stringDesc('AZZ', 'aZz') !== 0)
        `,
        output: `
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Исправляем сортировки',
    text: 'Исправьте функции \\(bubbleSort\\), \\(insertionSort\\), \\(selectionSort\\), \\(quickSort\\), \\(mergeSort\\) и \\(heapSort\\), чтобы они принимали вторым параметром компаратор. И сравнение элементов должно происходить с помощью него',
    note: null,
    examples: [
      {
        input: `
          console.log(bubbleSort([3, 1, 2], numberAsc))
          console.log(bubbleSort([3, 1, 2], (a, b) => b - a))
          console.log('---')

          console.log(insertionSort([3, 1, 2], numberAsc))
          console.log(insertionSort([3, 1, 2], (a, b) => b - a))
          console.log('---')

          console.log(selectionSort([3, 1, 2], numberAsc))
          console.log(selectionSort([3, 1, 2], (a, b) => b - a))
          console.log('---')

          console.log(quickSort([3, 1, 2], numberAsc))
          console.log(quickSort([3, 1, 2], (a, b) => b - a))
          console.log('---')

          console.log(mergeSort([3, 1, 2], numberAsc))
          console.log(mergeSort([3, 1, 2], (a, b) => b - a))
          console.log('---')

          console.log(heapSort([3, 1, 2], numberAsc))
          console.log(heapSort([3, 1, 2], (a, b) => b - a))
          console.log('---')
        `,
        output: `
          [1, 2, 3]
          [3, 2, 1]
          ---
          [1, 2, 3]
          [3, 2, 1]
          ---
          [1, 2, 3]
          [3, 2, 1]
          ---
          [1, 2, 3]
          [3, 2, 1]
          ---
          [1, 2, 3]
          [3, 2, 1]
          ---
          [1, 2, 3]
          [3, 2, 1]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Встроенная сортировка',
    text: 'У массивов есть встроенный метод \\(sort\\) для сортировки. Посмотрите как он работает с компаратором и без. Какие можно сделать выводы?',
    note: null,
    examples: [
      {
        input: `
          console.log([505, 4, 200, 19, 31].sort())
          console.log([505, 4, 200, 19, 31].sort(numberAsc))
          console.log(['b', 'a', 'd', 'c', 'aa', 'cc'].sort())
        `,
        output: `
          [19, 200, 31, 4, 505]
          [4, 19, 31, 200, 505]
          ['a', 'aa', 'b', 'c', 'cc', 'd']
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Генерируем упорядоченный по возрастанию массив',
    text: 'Напишите функцию \\(genAscArray\\), принимающую число \\(n\\), и возвращающую массив из \\(n\\) элементов, упорядоченных по возрастанию. Пусть он будет состоять из целых чисел от 0 до \\(n - 1\\)',
    note: null,
    examples: [
      {
        input: `
          console.log(genAscArray(4))
          console.log(genAscArray(7))
        `,
        output: `
          [0, 1, 2, 3]
          [0, 1, 2, 3, 4, 5, 6]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Генерируем упорядоченный по убыванию массив',
    text: 'Напишите функцию \\(genDescArray\\), принимающую число \\(n\\), и возвращающую массив из \\(n\\) элементов, упорядоченных по возрастанию. Пусть он будет состоять из целых чисел от \\(n - 1\\) до 0',
    note: null,
    examples: [
      {
        input: `
          console.log(genDescArray(4))
          console.log(genDescArray(7))
        `,
        output: `
          [3, 2, 1, 0]
          [6, 5, 4, 3, 2, 1, 0]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Генерируем массив со случайными числами',
    text: 'Напишите функцию \\(genRandArray\\), принимающую числа \\(n\\), \\(min\\) и \\(max\\), и возвращающую массив из \\(n\\) элементов, заполненный целыми числами в диапазоне от \\(min\\) до \\(max\\) включительно',
    note: 'Само собой, вывод может отличаться',
    examples: [
      {
        input: `
          console.log(genRandArray(4, 0, 2))
          console.log(genRandArray(4, 0, 2))
          console.log(genRandArray(7, -100, 100))
          console.log(genRandArray(7, -100, 100))
        `,
        output: `
          [0, 0, 2, 1]
          [2, 0, 1, 2]
          [58, -16, -2, -42, -87, 44, -61]
          [63, -59, 18, 78, -24, -53, 93]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время сортировок',
    text: 'Пришло время понять, какие сортировки более эффективные, а какие менее. Засечём время в разных вариантах. Долгие сортировки нет смысла запускать на больших массивах, так как это будет слишком долго. Если всё равно код работает слишком долго, попробуйте изменить \\(shortSize\\) и \\(longSize\\)',
    note: 'Сделайте выводы',
    examples: [
      {
        input: `
          const shortSize = 10000
          const longSize = 1000000
          const origAscShort = genAscArray(shortSize)
          const origDescShort = genDescArray(shortSize)
          const origRandShort = genRandArray(shortSize, 0, 10000)
          const origAscLong = genAscArray(longSize)
          const origDescLong = genDescArray(longSize)
          const origRandLong = genRandArray(longSize, 0, 10000)
          
          console.log('bubble')
          console.log(
              'short',
              getTimeOfManyTimes(1, bubbleSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, bubbleSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, bubbleSort, [...origRandShort], numberAsc)
          )
          
          console.log('\\ninsertion')
          console.log(
              'short',
              getTimeOfManyTimes(1, insertionSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, insertionSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, insertionSort, [...origRandShort], numberAsc)
          )
          
          console.log('\\nselection')
          console.log(
              'short',
              getTimeOfManyTimes(1, selectionSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, selectionSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, selectionSort, [...origRandShort], numberAsc)
          )
          
          console.log('\\nquick')
          console.log(
              'short',
              getTimeOfManyTimes(1, quickSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, quickSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, quickSort, [...origRandShort], numberAsc),
              'long',
              getTimeOfManyTimes(1, quickSort, [...origAscLong], numberAsc),
              getTimeOfManyTimes(1, quickSort, [...origDescLong], numberAsc),
              getTimeOfManyTimes(1, quickSort, [...origRandLong], numberAsc)
          )
          
          console.log('\\nmerge')
          console.log(
              'short',
              getTimeOfManyTimes(1, mergeSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, mergeSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, mergeSort, [...origRandShort], numberAsc),
              'long',
              getTimeOfManyTimes(1, mergeSort, [...origAscLong], numberAsc),
              getTimeOfManyTimes(1, mergeSort, [...origDescLong], numberAsc),
              getTimeOfManyTimes(1, mergeSort, [...origRandLong], numberAsc)
          )
          
          console.log('\\nheap')
          console.log(
              'short',
              getTimeOfManyTimes(1, heapSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, heapSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, heapSort, [...origRandShort], numberAsc),
              'long',
              getTimeOfManyTimes(1, heapSort, [...origAscLong], numberAsc),
              getTimeOfManyTimes(1, heapSort, [...origDescLong], numberAsc),
              getTimeOfManyTimes(1, heapSort, [...origRandLong], numberAsc)
          )
          
          console.log('\\nintegrated')
          const integratedSort = (a, cmp) => a.sort(cmp)
          console.log(
              'short',
              getTimeOfManyTimes(1, integratedSort, [...origAscShort], numberAsc),
              getTimeOfManyTimes(1, integratedSort, [...origDescShort], numberAsc),
              getTimeOfManyTimes(1, integratedSort, [...origRandShort], numberAsc),
              'long',
              getTimeOfManyTimes(1, integratedSort, [...origAscLong], numberAsc),
              getTimeOfManyTimes(1, integratedSort, [...origDescLong], numberAsc),
              getTimeOfManyTimes(1, integratedSort, [...origRandLong], numberAsc)
          )
        
        `,
        output: `
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Красивый вывод объектов',
    text: 'Напишите функцию \\(pprint\\), принимающую объект, выводящую на консоль объект в отформатированном виде. Для форматирования можно использовать JSON.stringify, передав первым параметром объект, вторым null, а третьим число 4',
    note: null,
    examples: [
      {
        input: `
          pprint({
              name: 'Vasya',
              age: 20
          })
        `,
        output: `
          {
              "name": "Vasya",
              "age": 20
          }
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Сортировка по возрасту',
    text: "Функция \\(ageSort\\). Дан массив объектов \\(a\\). Каждый элемент массива имеет ключи 'name' и 'age'. Верните этот же массив, но упорядочив объекты по числу в 'age' по убыванию. Можно использовать встроенную сортировку",
    note: null,
    examples: [
      {
        input: `
          pprint(ageSort([
              { name: 'Vasya', age: 20 },
              { name: 'Petya', age: 40 },
              { name: 'Ivan', age: 30 },
              { name: 'Fedya', age: 25 }
          ]))
        `,
        output: `
          [
              {
                  "name": "Petya",
                  "age": 40
              },
              {
                  "name": "Ivan",
                  "age": 30
              },
              {
                  "name": "Fedya",
                  "age": 25
              },
              {
                  "name": "Vasya",
                  "age": 20
              }
          ]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Перемешивание сортировкой',
    text: 'Функция \\(shuffleArray1\\). Дан массив чисел \\(a\\). Верните этот же массив, но перемешав все элементы случайным образом, используя встроенную сортировку с компаратором',
    note: 'Какая асимптотика у такого перемешивания?',
    examples: [
      {
        input: `
          console.log(shuffleArray1([1, 2, 3, 4, 5]))
          console.log(shuffleArray1([1, 2, 3, 4, 5]))
        `,
        output: `
          [4, 3, 2, 1, 5]
          [3, 4, 1, 2, 5]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Эффективное перемешивание',
    text: 'Функция \\(shuffleArray2\\). Дан массив чисел \\(a\\). Верните этот же массив, но перемешав все элементы случайным образом, уложившись в асимптотику O(n)',
    note: null,
    examples: [
      {
        input: `
          console.log(shuffleArray2([1, 2, 3, 4, 5]))
          console.log(shuffleArray2([1, 2, 3, 4, 5]))
        `,
        output: `
          [4, 3, 2, 1, 5]
          [3, 4, 1, 2, 5]
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время перемешивания',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          const origArray = genAscArray(1000000)
          console.log(getTimeOfManyTimes(1, shuffleArray1, [...origArray]))
          console.log(getTimeOfManyTimes(1, shuffleArray2, [...origArray]))
        `,
        output: `
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Линейный поиск в упорядоченном массиве',
    text: 'Функция \\(includes1\\). Дан упорядоченый массив \\(a\\) и значение \\(v\\). Функция должна вернуть true, если в массиве присутствует это значение, и false, если нет. Нужно реализовать полным перебором',
    note: null,
    examples: [
      {
        input: `
          console.log(includes1([4, 12, 43, 55, 182, 230], 4))
          console.log(includes1([4, 12, 43, 55, 182, 230], 182))
          console.log(includes1([4, 12, 43, 55, 182, 230], 230))
          console.log(includes1([4, 12, 43, 55, 182, 230], 1))
          console.log(includes1([4, 12, 43, 55, 182, 230], 56))
          console.log(includes1([4, 12, 43, 55, 182, 230], 321))
        `,
        output: `
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Бинарный поиск в упорядоченном массиве',
    text: 'Функция \\(includes2\\). Дан упорядоченый массив \\(a\\) и значение \\(v\\). Функция должна вернуть true, если в массиве присутствует это значение, и false, если нет. Нужно реализовать бинарным поиском',
    note: null,
    examples: [
      {
        input: `
          console.log(includes2([4, 12, 43, 55, 182, 230], 4))
          console.log(includes2([4, 12, 43, 55, 182, 230], 182))
          console.log(includes2([4, 12, 43, 55, 182, 230], 230))
          console.log(includes2([4, 12, 43, 55, 182, 230], 1))
          console.log(includes2([4, 12, 43, 55, 182, 230], 56))
          console.log(includes2([4, 12, 43, 55, 182, 230], 321))
        `,
        output: `
          true
          true
          true
          false
          false
          false
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Засекаем время поиска',
    text: 'Без функции. Запустить код для тестирования',
    note: null,
    examples: [
      {
        input: `
          const origArray = genAscArray(1000000)
          console.log(getTimeOfManyTimes(1, includes1, [...origArray]))
          console.log(getTimeOfManyTimes(1, includes2, [...origArray]))
        `,
        output: `
          ?
          ?
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Угадай число',
    text: 'Пользователь загадал число от 1 до 1000, и написал функцию \\(f\\). Эта функция принимает любое число, сравнивает его с загаданным, и возвращает отрицательное число, если загаданное меньше, положительное, если больше, и 0, если числа совпадают. Напишите функцию \\(guessNumber\\), принимающую функцию \\(f\\) от пользователя, которая не более, чем за 10 попыток угадает загаданное число. Все попытки (числа) должны выводиться на консоль',
    note: null,
    examples: [
      {
        input: `
          const checker = (n) => {
              return (x) => {
                  if (n < x) return -1
                  if (n > x) return 1
                  return 0
              }
          }
          guessNumber(checker(32))
          console.log('---')
          guessNumber(checker(777))
        `,
        output: `
          500
          250
          125
          62
          31
          46
          38
          34
          32
          ---
          500
          750
          875
          812
          781
          765
          773
          777
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Глава в книге',
    text: 'Функция \\(bookChapter\\). Дан массив \\(a\\). Его длина равна количеству глав в книге. Каждый элемент содержит целое положительное число - количество страниц в главе. Верните функцию, принимающую номер страницы, возвращающую номер главы (нумерация с 1), к которой относится данная страница. \\(bookChapter\\) должна иметь асимптотику не более O(n), а возвращаемая функция не более O(log(n))',
    note: null,
    examples: [
      {
        input: `
          const book1Find = bookChapter([10, 10, 10, 10])
          console.log(book1Find(1))
          console.log(book1Find(10))
          console.log(book1Find(30))
          console.log(book1Find(31))
          console.log('---')
          const book2Find = bookChapter([50, 10, 15, 40, 50, 100])
          console.log(book2Find(49))
          console.log(book2Find(60))
          console.log(book2Find(80))
          console.log(book2Find(265))
        `,
        output: `
          1
          1
          3
          4
          ---
          1
          2
          4
          6
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Баннеры из яндекса',
    text: "Функция \\(yandexBanners\\). Дан массив объектов. В каждом объекте есть ключи 'name' (строковое) и 'value' (целое положительное). Нужно вернуть функцию, которая будет давать объекты из массива случайным образом, но вероятность появления каждого баннера должна быть пропорциональна его 'value'",
    note: "Например, если у баннеров 'value' равны 3, 1 и 2, значит возвращаемая функция должна вернуть первый баннер с вероятностью 3/6, второй с вероятностью 1/6 и третий с вероятностью 2/6. \\(yandexBanners\\) должна работать не дольше O(n), а возвращаемая функция не дольше O(log(n))",
    examples: [
      {
        input: `
          const getNext = yandexBanners([
              { name: 'A', value: 3 },
              { name: 'B', value: 1 },
              { name: 'C', value: 2 }
          ])
          const counts = { A: 0, B: 0, C: 0 }
          for (let i = 0; i < 10000; i++) {
              const b = getNext()
              counts[b.name]++
          }
          console.log(counts)
        `,
        output: `
          { A: 5000, B: 1666, C: 3334 }
        `
      }
    ]
  },
  {
    component: CodeTest,
    lang: 'js',
    name: 'Поиск ближайшего',
    text: 'Функция \\(findClosest\\). Дан упорядоченный по неубыванию массив чисел \\(a\\) и число \\(x\\). В массиве нужно найти число, ближайшее к \\(x\\) по значению (чтобы разница элемента массива и \\(x\\) была минимальной). Если есть два таких числа, вывести меньшее',
    note: null,
    examples: [
      {
        input: `
          console.log(findClosest([4, 12, 43, 55, 182, 230], 0))
          console.log(findClosest([4, 12, 43, 55, 182, 230], 8))
          console.log(findClosest([4, 12, 43, 55, 182, 230], 14))
          console.log(findClosest([4, 12, 43, 55, 182, 230], 182))
          console.log(findClosest([4, 12, 43, 55, 182, 230], 220))
          console.log(findClosest([4, 12, 43, 55, 182, 230], 500))
        `,
        output: `
          4
          4
          12
          182
          230
          230
        `
      }
    ]
  }
]

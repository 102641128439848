<template>
  <h2>Универсальность сортировок</h2>
  <p>Мы разобрались как сортировать числа по возрастанию. А что если нам нужно сортировать не только по возрастанию, или даже не только числа? Каждый раз переписывать сортировку для новых типов данных? Было бы очень неудобно.</p>
  <p>По сути, в любых сортировках происходят сравнения элементов только по парам. Нельзя сравнивать за раз три или более элементов. И, в принципе, мы можем логику сравнения заключить в отдельную функцию (условно назовём такую функцию компаратором). Компаратор будет принимать два элемента массива и возвращать информацию о том, какой элемент должен быть левее, какой правее. Если функция сортировки будет принимать такой компаратор и сравнивать элементы с помощью него, то сама сортировка будет универсальной.</p>
  <p>Мы сказали, что компаратор должен возвращать результат сравнения, по которому сортировка должна понять, нужно менять элементы местами или нет. Остальсь определиться, в каком виде должен быть результат сравнения.</p>
  <p>В принципе, это может быть просто булева функция, которая возвращает true, если нужно обменивать и false, если не нужно. Но у пары элементов может быть три состояния, а не два: первый должен быть левее, первый должен быть правее, либо порядок равнозначен (от обмена ничего не изменится). Поэтому в JavaScript решили использовать не булево значение, а число. Если первый должен быть левее, компаратор должен вернуть любое отрицательное число, если правее - положительное, если равнозначны - 0.</p>
  <p>Например, компаратор для упорядочивания чисел по возрастанию может выглядеть так:</p>
  <Code lang="js" :code="`
    (a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    }
  `"
  />
  <p>А компаратор для упорядочивания объектов по значению ключа value по убыванию может выглядеть так:</p>
  <Code lang="js" :code="`
    (a, b) => {
      if (a.value > b.value) return -1
      if (a.value < b.value) return 1
      return 0
    }
  `"
  />
  <p>Но обычно для чисел используется более короткая версия:</p>
  <Code lang="js" :code="`
    // числа по возрастанию
    (a, b) => a - b
    // value по убыванию
    (a, b) => b.value - a.value
  `"
  />
</template>

<script>
import Code from '@/components/ui/Code'

export default {
  components: {
    Code
  },
  props: ['task']
}
</script>

<template>
  <h2>Как решать эту тему</h2>
  <div>Для начала, ознакомьтесь с видео</div>
  <div class="iframe-16-9">
    <iframe src="https://www.youtube.com/embed/rZsZ3LIAUSw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  <p>В некоторых задачах используется функция, засекающая время выполнения других функций. Её нужно вставить в свой код</p>
  <Code lang="js" :code="`
    const getTimeOfManyTimes = (n, f, ...args) => {
        const now = new Date()
        for (let i = 0; i < n; i++) {
            f(...args)
        }
        return new Date() - now
    }
  `"
  />
  <p>Как видим, первым параметром она принимает количество запусков, вторым - функцию, а далее идут аргументы для функции. Возвращает время в миллисекундах, которое заняло выполнение функции n раз.</p>
  <p>В задачах будет написано, как её применять</p>
</template>

<script>
import Code from '@/components/ui/Code'

export default {
  components: {
    Code
  },
  props: ['task']
}
</script>
